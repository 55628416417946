import { defineStore } from 'pinia';
import { type LooseObject, type Nullable } from '@/types/generic';
import { handleError } from '~/utils/errors';
import { useProducts } from '~/stores/products';
import { StripeSubscription } from '~/types/subscriptions';
import { marked } from 'marked';


export interface CustomerDetails {
  name: string;
  phone: string;
  plan?: LooseObject;
  referrer?: string;
  promo?: string;
}

export interface Customer {
  id: number;
  // account_id: number;
  auth0_sub: string;
  name: string;
  email: string;
  // dob: string;
  phone: string;
  // marketing: string;
  stripe_id: string;
  created_at: string;
  updated_at: string;
}

export interface SubscriptionsList {
  broadband: StripeSubscription[];
}

interface State {
  user: Nullable<Customer>;
  subscriptions: {
    active: StripeSubscription[];
  };
  subscriptionsLoaded: boolean;
  isLoading: boolean;
  billingDescriptions : string[]; // Add this line
  globalMessage: string; // Add this line

}

export const useAuthStore = defineStore('auth', {
  state: (): State => ({
    user: null,
    subscriptions: {
      active: []
    },
    subscriptionsLoaded: false,
    isLoading: false,
    billingDescriptions: [], // Add this line
    globalMessage: '',
  }),
  getters: {
    customerExists(state) {
      return !!state.user;
    },
    subscriptionsList(state): SubscriptionsList {
      const list: SubscriptionsList = {
        broadband: []
      };

      const products = useProducts();

      list.broadband = state.subscriptions.active;

      return list;
    },
    monthlyCost(state): number {
      if (!state.subscriptions.active.length) {
        return 0;
      }
      const prices: number[] = [];
      state.subscriptions.active.forEach((subscription) => {
        if (subscription.plan && subscription.plan.amount) {
          prices.push(subscription.plan.amount);
        }
      });
      console.log(prices);
      return prices.reduce((a, b) => a + b, 0);
    },
  },
  actions: {
    async loadUser() {
      if (this.isLoading || this.user) {
        return;
      }
      this.isLoading = true;
      try {
        const accessToken = await this.$auth0.getAccessTokenSilently();
        if (!accessToken) {
          this.isLoading = false;
          this.user = null;
          return;
        }
        const response = await this.$api.get<Customer>('/customer', {
          headers: { Authorization: `Bearer ${accessToken}` },
        });

        if (response.status === 200 || response.status === 201) {
          this.user = response._data.data;
          this.globalMessage = await marked.parse(response._data.globalMessage);

          this.isLoading = false;
        }
      } catch (err: any) {
        this.user = null;
        this.isLoading = false;
        // handleError(err);
      }
    },


    // async updateUser(details: CustomerDetails) {
    //   const accessToken = await this.$auth0.getAccessTokenSilently();

    //   if (!this.customerExists) {
    //     return this.createUser(details);
    //   }

    //   const submitData: CustomerDetails = {
    //     name: details.name,
    //     phone: details.phone,
    //   };

    //   if (details.plan) {
    //     submitData.plan = details.plan;
    //   }
    //   if (details.promo) {
    //     submitData.promo = details.promo;
    //   }
    //   if (details.referrer) {
    //     submitData.referrer = details.referrer;
    //   }

    //   try {
    //     const response = await this.$api.put<Customer, CustomerDetails>('/customer', submitData, {
    //       headers: { Authorization: `Bearer ${accessToken}` },
    //     });
    //     if (response.status === 200 || response.status === 201) {
    //       this.user = response._data.data;
    //     }
    //     return true;
    //   } catch (err) {
    //     this.user = null;
    //     handleError(err);
    //   }
    //   return false;
    // },
    async getAccessTokenSilently() {
      return await this.$auth0.getAccessTokenSilently();
    },
    async fetchSubscriptions() {
      if (this.subscriptionsLoaded) {
        return;
      }
      this.isLoading = true;
      const accessToken = await this.$auth0.getAccessTokenSilently();

      try {
        const response = await this.$api.get<LooseObject[]>('/customer/subscriptions', {
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        if (response.status === 200) {
          this.subscriptions = response._data.data || [];
          this.billingDescriptions = response._data.data.billingDescriptions || []; // Add this line
          this.subscriptionsLoaded = true;
        }
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        handleError(err);
      }
    },
  },
});
